import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { logError } from "@telia/b2x-logging";
import { computed, ref } from "vue";
import { ActiveFilter, InvoiceParams } from "../interfaces";
import { fetchInvoices } from "../services/corp-customer-invoices";
import { analytics, trackEventInvoices } from "../utils/analytics";
import { createInvoiceListData, createLayout } from "../utils/create-state";
import { hasSDW } from "../utils/has-sdw";
import { createFromDate, setToLocaleDateString } from "../utils/use-date";
import useTableData from "./table-data";

const FILTER_KEYS = {
  SUBSCRIPTION: "subscription",
  ACCOUNT_NR: "accountNumber",
  DATE_RANGE: "dateRange",
  INVOICE_NR: "invoiceNumber",
  ORGANISATION: "organisation",
} as const;

const invoiceListData = ref(createInvoiceListData());
const layout = ref(createLayout());

const selectedOrganisation = ref<string>("");
const activeFilters = ref<ActiveFilter[]>([]);

const { setTableData } = useTableData();

const useInvoiceListData = () => {
  const init = async (): Promise<void> => {
    try {
      await tryToGetScopeId();
      setSelectedOrganisationFromLocalStorage();
      await setSDW();
    } catch (e) {
      layout.value.showError = true;
      layout.value.showLoading = false;
      layout.value.loadingTemplate = false;
      _logError("Failed to initialize the page");
    }
  };

  const setSelectedOrganisationFromLocalStorage = () => {
    const selectedOrganisationFromLocalStorage = window.localStorage.getItem(
      `selectedOrganisation-${invoiceListData.value.scopeId}`
    );
    if (selectedOrganisationFromLocalStorage) {
      selectedOrganisation.value = selectedOrganisationFromLocalStorage;
    }
  };

  const setSelectedOrganisationAndFetch = async (event) => {
    if (event.detail) {
      removeFilterByKey(FILTER_KEYS.SUBSCRIPTION);
      removeFilterByKey(FILTER_KEYS.ACCOUNT_NR);
      selectedOrganisation.value = event.detail;
      changePage(1);

      if (!invoiceListData.value.scopeId) {
        try {
          await tryToGetScopeId();
        } catch (e) {
          _logError("Failed to get scopeId");
        }
      }

      invoiceListData.value.scopeId && setSDW();
      invoiceListData.value.scopeId && fetchAndSetInvoiceList();
    } else {
      _logError("Organisation tscid was not returned from b2b-layout");
    }
  };

  const fetchAndSetInvoiceList = async (trackAnalytics = true) => {
    await trytoGetInvoiceList(trackAnalytics);
    setTableData(
      invoiceListData.value.scopeId,
      selectedOrganisation.value,
      invoiceListData.value.invoicesList
    );
    createGaEventForMissingInvoices();
  };

  const createGaEventForMissingInvoices = () => {
    if (isInvoicesMissingOnPage.value) {
      const action = `${analytics.action.FETCH} ${invoiceListData.value.pagination.pageSize}`;
      const label = `${invoiceListData.value.invoicesList.length}`;
      trackEventInvoices(label, action);
    }
  };

  const isInvoicesMissingOnPage = computed(() => {
    return (
      invoiceListData.value.invoicesList.length < invoiceListData.value.pagination.pageSize &&
      invoiceListData.value.pagination.pageSize < invoiceListData.value.pagination.totalInvoices &&
      invoiceListData.value.invoiceTo < invoiceListData.value.pagination.totalInvoices
    );
  });

  const invoiceTablePaginationChange = (detail: { page: number; pageSize: number }) => {
    invoiceTablePaginationChangeGoogleAnalytics(detail);
    changePage(detail.page, detail.pageSize);
    fetchAndSetInvoiceList(false);
  };

  const removeFilterByKey = (filterKey: string) => {
    switch (filterKey) {
      case FILTER_KEYS.ACCOUNT_NR:
        invoiceListData.value.account.billingAccountNr = "";
        invoiceListData.value.account.accountNr = "";
        break;
      case FILTER_KEYS.SUBSCRIPTION:
        invoiceListData.value.selectedSubscriptionId = "";
        break;
      case FILTER_KEYS.INVOICE_NR:
        invoiceListData.value.invoiceNr = "";
        break;
      case FILTER_KEYS.DATE_RANGE:
        resetDate();
        break;
      default:
        break;
    }

    removeFromActiveFilters(filterKey);
  };

  const setSDW = async () => {
    invoiceListData.value.hasSDW = await hasSDW(
      invoiceListData.value.scopeId,
      selectedOrganisation.value
    );
  };

  const setInvoiceNrAndFetch = (invoiceNr: string) => {
    invoiceListData.value.invoiceNr = invoiceNr;
    setActiveFilters(FILTER_KEYS.INVOICE_NR, invoiceNr);
    changePage(1);
    fetchAndSetInvoiceList();
  };

  const setSubscriptionIdAndFetch = (subscriptionId: string) => {
    invoiceListData.value.selectedSubscriptionId = subscriptionId;
    setActiveFilters(FILTER_KEYS.SUBSCRIPTION, subscriptionId);
    changePage(1);
    fetchAndSetInvoiceList();
  };

  const setAccountNrAndFetch = (
    inputValue: string,
    billingAccountNr: string,
    accountNr: string
  ) => {
    invoiceListData.value.account.accountNr = accountNr;
    invoiceListData.value.account.billingAccountNr = billingAccountNr;
    // Temporary solution, remove once m-number is completely gone in MYBD-16037
    inputValue.toLowerCase().startsWith("m")
      ? setActiveFilters(FILTER_KEYS.ACCOUNT_NR, billingAccountNr)
      : setActiveFilters(FILTER_KEYS.ACCOUNT_NR, accountNr);
    changePage(1);
    fetchAndSetInvoiceList();
  };

  const setDatesAndFetch = (
    fromDate: Date,
    toDate: Date,
    separator: string,
    shouldResetDates: boolean
  ) => {
    invoiceListData.value.from_date = fromDate;
    invoiceListData.value.to_date = toDate;

    if (shouldResetDates) {
      removeFromActiveFilters(FILTER_KEYS.DATE_RANGE);
    } else {
      const dateRange =
        setToLocaleDateString(fromDate) + ` ${separator} ` + setToLocaleDateString(toDate);
      setActiveFilters(FILTER_KEYS.DATE_RANGE, dateRange);
    }
    changePage(1);
    fetchAndSetInvoiceList();
  };

  const removeAllFilters = () => {
    Object.values(FILTER_KEYS).forEach((entry) => removeFilterByKey(entry));
    resetDate();
  };

  return {
    invoiceListData,
    layout,
    isInvoicesMissingOnPage,
    activeFilters,
    selectedOrganisation,
    init,
    invoiceTablePaginationChange,
    removeFilterByKey,
    setInvoiceNrAndFetch,
    setSubscriptionIdAndFetch,
    setAccountNrAndFetch,
    setDatesAndFetch,
    fetchAndSetInvoiceList,
    removeAllFilters,
    setSelectedOrganisationAndFetch,
  };
};

export default useInvoiceListData;

const trytoGetInvoiceList = async (trackAnalytics: boolean) => {
  try {
    layout.value.showLoading = true;
    layout.value.showError = false;

    const queryParameters = createQueryParameters();
    const response = await fetchInvoices(queryParameters);
    invoiceListData.value.pagination.totalInvoices = response.paginateTotalInvoices;

    if (trackAnalytics) {
      trackEventInvoices(createAnalyticsActiveFiltersLabel(), analytics.action.FILTER, [
        {
          type: analytics.customDimensions.SEARCH_FILTER,
          value: invoiceListData.value.pagination.totalInvoices.toString(),
        },
      ]);
    }

    invoiceListData.value.invoicesList = response.invoiceList;
  } catch (error) {
    layout.value.showError = true;
    invoiceListData.value.pagination.totalInvoices = 0;
    _logError("Failed to fetch invoices");
    invoiceListData.value.invoicesList = [];
  } finally {
    layout.value.showLoading = false;
    layout.value.loadingTemplate = false;
  }
};

const setActiveFilters = (key: string, value: string) => {
  removeFromActiveFilters(key);
  activeFilters.value.push({
    key,
    value,
  });
};

const removeFromActiveFilters = (filterKeyToRemove: string) => {
  activeFilters.value = activeFilters.value.filter(
    (activeFilter) => activeFilter.key != filterKeyToRemove
  );
};

const resetDate = () => {
  invoiceListData.value.to_date = new Date();
  invoiceListData.value.from_date = createFromDate(3);
};

const changePage = (
  pageNumber: number,
  pageSize: number = invoiceListData.value.pagination.pageSize
) => {
  invoiceListData.value.pagination.pageSize = pageSize;
  invoiceListData.value.invoiceTo = calculateInvoiceTo(pageNumber);
  invoiceListData.value.invoiceFrom = calculateInvoiceFrom(pageNumber);
};

const calculateInvoiceFrom = (pageNumber: number): number => {
  const endInvoiceNumber = invoiceListData.value.pagination.pageSize * pageNumber;
  const numberOfInvoices = invoiceListData.value.pagination.pageSize - 1; // removing one to offset starting point from last page endpoint
  const startInvoiceFrom = endInvoiceNumber - numberOfInvoices;
  return startInvoiceFrom;
};

const calculateInvoiceTo = (pageNumber: number): number => {
  const endInvoiceNumber = invoiceListData.value.pagination.pageSize * pageNumber;
  return endInvoiceNumber;
};

const invoiceTablePaginationChangeGoogleAnalytics = (detail: {
  page: number;
  pageSize: number;
}) => {
  const label = pageSizeHasChanged(detail.pageSize)
    ? `Page size selected ${detail.pageSize}`
    : `Page selected ${detail.page}`;
  trackEventInvoices(label, analytics.action.CLICK);
};

const pageSizeHasChanged = (newpageSize: number): boolean => {
  return newpageSize !== invoiceListData.value.pagination.pageSize;
};

const createAnalyticsActiveFiltersLabel = (): string => {
  return activeFilters.value.map((activeFilter) => activeFilter.key).join("+");
};

const createQueryParameters = (): InvoiceParams => {
  return {
    scopeId: invoiceListData.value.scopeId,
    tscid: selectedOrganisation.value,
    fromInvoiceDate: setToLocaleDateString(invoiceListData.value.from_date),
    toInvoiceDate: setToLocaleDateString(invoiceListData.value.to_date),
    invoiceFrom: invoiceListData.value.invoiceFrom,
    invoiceTo: invoiceListData.value.invoiceTo,
    invoiceNumber: invoiceListData.value.invoiceNr ? invoiceListData.value.invoiceNr : undefined,
    subscriptionNumber: invoiceListData.value.selectedSubscriptionId
      ? invoiceListData.value.selectedSubscriptionId
      : undefined,
    accountNumber: invoiceListData.value.account.accountNr
      ? invoiceListData.value.account.accountNr
      : undefined,
  };
};

const tryToGetScopeId = async (): Promise<void> => {
  try {
    invoiceListData.value.scopeId = await getScopeIdOrThrow();
  } catch {
    _logError("Failed to get scope id");
    throw new Error();
  }
};

const _logError = (message: string) => {
  logError("b2b-invoice-landing-page", message);
};
