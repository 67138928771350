import { ref } from "vue";
import { Invoice, TableButtonData, TableDataRow } from "../interfaces";
import { translateMixin } from "../locale";
import { PAYMENT_STATUS_BADGE } from "../utils/payment-status-badges";
import { formatAmountToLocaleString } from "../utils/use-date";

const tableData = ref<TableDataRow[]>([]);
const t = translateMixin.methods.t;

const useTableData = () => {
  const setTableData = (scopeId: string, tscid: string, invoicesList: Invoice[]) => {
    tableData.value = mapInvoiceListToTableData(scopeId, tscid, invoicesList);
  };

  return { tableData, setTableData, createBillingAccountUrl };
};

export default useTableData;

const mapInvoiceListToTableData = (
  scopeId: string,
  tscid: string,
  invoicesList: Invoice[]
): TableDataRow[] => {
  return invoicesList.map((invoice, index) => {
    const table = [
      setupPaymentStatusColumn(invoice.paymentStatus),
      invoice.invoiceNumber,
      setupBillingAccountColumn(scopeId, tscid, invoice.accountNumber),
      invoice.invoiceDate,
      invoice.invoiceDueDate,
      formatAmountToLocaleString(invoice.totalAmount),
      setupPdfButtonData(index),
    ];

    table.push(setupPreviewInvoiceButtonData(index, invoice.billingSystemID));

    table.push(setupXmlLinkData(scopeId, tscid, invoice.invoiceNumber, invoice.billingSystemID));

    return table;
  });
};

const setupPaymentStatusColumn = (status) => {
  return {
    variant: PAYMENT_STATUS_BADGE[status].variant,
    content: t(PAYMENT_STATUS_BADGE[status].translationKey),
  };
};

const setupBillingAccountColumn = (
  scopeId: string,
  tscid: string,
  billingAccountNumber: string
) => {
  const url = createBillingAccountUrl(scopeId, tscid, billingAccountNumber);
  return {
    href: url,
    content: billingAccountNumber,
    disableVisited: true,
  };
};

const createBillingAccountUrl = (
  scopeId: string,
  tscid: string,
  billingAccountNumber: string
): string => {
  return `/foretag/mybusiness/${scopeId}/fakturor/hantera-fakturering/${tscid}/${billingAccountNumber}`;
};

const addDownloadPdfButtonText = () => {
  return t("table.tableData.download");
};

const setupPdfButtonData = (index: number): TableButtonData => {
  return {
    rowNumber: index,
    attrs: {
      text: addDownloadPdfButtonText(),
      variant: "text",
      leftIcon: JSON.stringify({ name: "download", size: "md" }),
      type: "button",
    },
  };
};

const setupPreviewInvoiceButtonData = (index: number, billingSystemID: string): TableButtonData => {
  const MOBILE_BILLING_SYSTEM_ID = "MOB";
  return {
    rowNumber: index,
    attrs: {
      text: t("table.tableData.previewInvoiceButton"),
      variant: "text",
      disabled: billingSystemID !== MOBILE_BILLING_SYSTEM_ID,
      leftIcon: JSON.stringify({ name: "doc", size: "md" }),
      type: "button",
    },
  };
};

const setupXmlLinkData = (
  scopeId: string,
  tscid: string,
  invoiceId: string,
  billingSystemId: string
) => {
  return {
    href: createDownloadXmlUrl(scopeId, tscid, invoiceId, billingSystemId),
    content: "Ladda ner XML",
    disableVisited: true,
  };
};

const createDownloadXmlUrl = (
  scopeId: string,
  tscid: string,
  invoiceId: string,
  billingSystemId: string
) => {
  return `/.api/corp-customer-invoices/v1/${scopeId}/debug/xml?tscid=${tscid}&invoiceId=${invoiceId}&billingSystemID=${billingSystemId}`;
};
